import { sortBy, orderBy } from "lodash"
import * as Sentry from "@sentry/browser"
import { Session, BonnySessionData } from "../types/freudTypes"
const baseUrl = process.env.GATSBY_FREUD_BASE_URL

export const getSignedUpSession = async (broadcastIdentifier: string, { anonymousToken }) => {
    const authHeaderValue = `anonymous ${anonymousToken}`

    const session = await fetch(baseUrl + "/sessions/my/" + broadcastIdentifier, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: authHeaderValue,
        },
    })
        .then((result) => result.json())
        .catch((error) => console.error(error))
    return session
}

export const getSessionInfo = async (sessionId, user) => {
    let idToken
    if (user) {
        idToken = await user.getIdToken()
    }

    const response = await fetch(`${baseUrl}/sessions/available/${sessionId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `idToken ${idToken}`,
        },
    })

    const result = await response.json()
    return result
}

export const signUpToSession = async (sessionId, user) => {
    console.log("called sign up to session")
    let idToken
    if (user) {
        idToken = await user.getIdToken()
    }

    const response = await fetch(baseUrl + "/sessions/available/signups", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `idToken ${idToken}`,
        },
        body: JSON.stringify({ sessionId }),
    })
    if (response.ok) return

    const result = await response.json().catch(console.log)

    if (result.error) {
        throw result.error
    }

    return result
}

export const cancelSignUp = async (sessionId, user) => {
    let idToken
    if (user) {
        idToken = await user.getIdToken()
    }
    await fetch(baseUrl + "/sessions/my/signups", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `idToken ${idToken}`,
        },
        body: JSON.stringify({ sessionId: sessionId }),
    }).catch((error) => console.error(error))
}

export const listSessions = async (anonymousToken) => {
    const sessions = await fetch(baseUrl + "/sessions/available", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `anonymous ${anonymousToken}`,
        },
    })
        .then((result) => result.json())
        .catch((error) => console.error(error))
    return sessions
}

function hasSessionEnded(session) {
    const endTime = getSessionEndTime(session)
    return endTime && endTime < Date.now()
}

export function getSessionEndTime(session) {
    if (session.endTime) {
        return session.endTime
    } else if (session.startTime && session.duration) {
        return session.startTime + session.duration
    }
}

export const listStreams = async (anonymousToken: string) => {
    return listSessions(anonymousToken)
        .then((sessions) => sessions.filter((s) => s.type === "groupInfinite"))
        .then((streams) => sortBy(streams, "name"))
        .then((streams) => {
            if (streams.length < 4) {
                Sentry.captureMessage("Insufficient Infinite Streams")
            }
            return streams
        })
}

export const listGroupSessions = async (user) => {
    return listSessions(user)
        .then((sessions) => sessions.filter((s) => s.type === "groupGuided"))
        .then((sessions) => sessions.filter((s) => !hasSessionEnded(s)))
        .then((sessions) => sortBy(sessions, "startTime"))
}

//valid types are "Preparation Steps Completed", "Journal Entries" and "Session Goals"
export const updatePreparationMetadata = async (user, sessionId, type, value) => {
    const idToken = await user.getIdToken()
    const sessions = await fetch(baseUrl + "/sessions/my/signups/metadata/preparation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `idToken ${idToken}`,
        },
        body: JSON.stringify({
            sessionId: sessionId,
            type: type,
            value: JSON.stringify(value),
        }),
    }).catch((error) => console.error(error))
    return sessions
}

export const getPreparationMetadata = async (user, sessionId) => {
    console.log("get prep data called")
    const idToken = await user.getIdToken()
    const metadata = await fetch(baseUrl + "/sessions/my/" + sessionId + "/signups/metadata/preparation/", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `idToken ${idToken}`,
        },
    })
        .then((result) => result.json())
        .catch((error) => console.error(error))
    return metadata
}

export const isSessionPrepared = async (user, sessionId) => {
    const prepMetadata = await getPreparationMetadata(user, sessionId)
    return (
        prepMetadata &&
        prepMetadata["Preparation Steps Completed"] &&
        prepMetadata["Preparation Steps Completed"].length > 0
    )
}
type MetadataType = "Pre Emotional State" | "Post Emotional State" | "Ratings"
type SignupMetadata = ({ emotion: string; value: number | null } | { rating: string; value: number })[]
export const updateSignupMetadata = async (
    sessionId,
    type: MetadataType,
    value: SignupMetadata,
    { anonymousToken }
) => {
    const authHeaderValue = `anonymous ${anonymousToken}`

    await fetch(baseUrl + "/sessions/my/signups/metadata/session", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: authHeaderValue,
        },
        body: JSON.stringify({
            sessionId: sessionId,
            type: type,
            value: JSON.stringify(value),
        }),
    }).catch((error) => console.error(error))
}

export const startSessionEarly = async (
    sessionId: string,
    anonymousToken: string | undefined
): Promise<BonnySessionData> => {
    const authHeaderValue = `anonymous ${anonymousToken}`

    return fetch(`${baseUrl}/sessions/my/${sessionId}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json", Authorization: authHeaderValue },
        body: JSON.stringify({ scheduledStart: Date.now() - 300 }),
    }).then((res) => res.json())
}

export const getSignupMetadata = async (sessionId, { anonymousToken }) => {
    const authHeaderValue = `anonymous ${anonymousToken}`

    const metadata = await fetch(baseUrl + "/sessions/my/" + sessionId + "/signups/metadata/session/", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: authHeaderValue,
        },
    })
        .then((result) => result.json())
        .catch((error) => console.error(error))
    return metadata
}

export const updateIntegrationData = async (user, sessionId, value) => {
    let idToken
    if (user) {
        idToken = await user.getIdToken()
    }
    await fetch(baseUrl + "/sessions/my/signups/metadata/integration", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `idToken ${idToken}`,
        },
        body: JSON.stringify({
            sessionId: sessionId,
            value: JSON.stringify(value),
        }),
    }).catch((error) => console.error(error))
}

export const getIntegrationData = async (user, sessionId) => {
    let idToken
    if (user) {
        idToken = await user.getIdToken()
    }
    console.log("getting integration data")
    const metadata = await fetch(baseUrl + "/sessions/my/" + sessionId + "/signups/metadata/integration", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `idToken ${idToken}`,
        },
    })
        .then((result) => (result.status === 200 ? result.json() : null))
        .catch((error) => console.error(error))
    return metadata
}

export const isSessionIntegrated = async (user, sessionId) => {
    return !!(await getIntegrationData(user, sessionId))
}

export const addQaulitativeFeedback = async ({ anonymousToken }, sessionId, value) => {
    const authHeaderValue = `anonymous ${anonymousToken}`

    await fetch(baseUrl + "/sessions/my/signups/metadata/session/feedback", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: authHeaderValue,
        },
        body: JSON.stringify({
            sessionId: sessionId,
            value: JSON.stringify(value),
        }),
    }).catch((error) => console.error(error))
}

export const getSessionInformationWithSecret = async (
    sessionId: string,
    creatorId: string,
    hash: string
): Promise<Session> => {
    console.log("getSessionInformation called, ", sessionId, creatorId, hash)
    try {
        const session = await fetch(`${baseUrl}/sessions/${sessionId}?creatorId=${creatorId}&secret=${hash}`, {
            method: "GET",
        })
        return session.json()
    } catch (e) {
        console.error(e)
        throw new Error(e)
    }
}
